/**
 * 接口代理配置
 *
 * @type {Object}
 */
import { isApp } from '@/utils/tools';
import { apiHost } from './setup';

export type ParamsType = {
    url: string,
    headers?: <T>(x: T) => T | { [key: string]: string | number }
    params?: <T>(x: T) => T | { [key: string]: string | number }
    responseHandle?: <T>(x: T) => T
}

interface ApiInterface {
    [key: string]: ParamsType
}

const apis: ApiInterface = {
  '@api/': {
    url: `${apiHost.api}/api/`
  },
  '@bapi/': {
    url: `${apiHost.api}/authorize/bapi/`
  },
  // 国际站接口
  '@bapiGB/': {
    url: `${apiHost.apiGB}/bapi/`
  },
  '@risk/': {
    url: `${apiHost.riskApi}/api/v1/datacollect/`
  },
  '@auth/': {
    url: `${apiHost.api}/authorize/auth/`
  },
  '@group/': {
    url: `${apiHost.api}/authorize/group/`
  },
  //  怕影响原有逻辑，$api开头的接口需要调用极验在h5不能转成mapi
  '$api/': {
    url: `${apiHost.api}/api/`
  },
  '$bapi/': {
    url: `${apiHost.api}/bapi/`
  },
  '@mock/': {
    url: 'https://mock.apifox.cn/m1/2432145-0-default/'
  }
};

export default apis;
